import { Directive, ElementRef, Input, AfterViewInit, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[responsive]'
})
export class ResponsiveDirective implements AfterViewInit, OnDestroy {

  @Input() classOnPhone = '';
  @Input() classOnTablet = '';
  @Input() classOnLaptop = '';
  @Input() classOnPc = '';
  subscription: Subscription;
  originalStyle = '';
  originalClass = '';
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.originalStyle = (this.el.nativeElement as HTMLElement).style.cssText;
    this.originalClass = ((this.el.nativeElement as HTMLElement).classList as any).value as string + ' ';

    if (window.matchMedia('(max-width: 768px)').matches) {
      ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnPhone + ' ';
    } else if (window.matchMedia('(min-width: 769px) and (max-width: 992px)').matches) {
      ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnTablet + ' ';
    } else if (window.matchMedia('(min-width:993px) and (max-width: 1200px)').matches) {
      ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnLaptop + ' ';
    } else if (window.matchMedia('(min-width:1201px)').matches) {
      ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnPc + ' ';
    }
    const ev = fromEvent(window, 'resize');
    this.subscription = ev.pipe(
      debounceTime(20),
      distinctUntilChanged()
    )
    .subscribe(e => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnPhone + ' ';
      } else if (window.matchMedia('(min-width: 769px) and (max-width: 992px)').matches) {
        ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnTablet + ' ';
      } else if (window.matchMedia('(min-width:993px) and (max-width: 1200px)').matches) {
        ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass  + this.classOnLaptop + ' ';
      } else if (window.matchMedia('(min-width:1201px)').matches) {
        ((this.el.nativeElement as HTMLElement).classList as any).value = this.originalClass + this.classOnPc + ' ';
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
