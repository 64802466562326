import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  styleUrls: ['./pages-layout.component.css']
})
export class PagesLayoutComponent implements OnInit {

  @Input() activePage = '';

  constructor() {}

  ngOnInit() {}
}
