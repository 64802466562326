import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';

export interface IForm {
  email: string;
  name: string;
  message: string;
  subject: string;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor() { }

  sendMail(sendMailForm: NgForm) {
    const formData = sendMailForm.value as IForm;
  }

  ngOnInit() {}
}
